<template>
  <v-select
    v-model="selection"
    :items="options"
    :label="fieldName"
    class="boolean-field-renderer"
    :placeholder="$translate('pleaseSelect')"
    :class="{ 'is-required': isRequired }"
    :rules="validationRules"
    :persistent-placeholder="true"
    :data-qa="qaString"
  />
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue';
import { useDynamicValueGetSet } from '@/dynamic-form/composables/use-dynamic-value-get-set';
import { useFieldname } from '@/dynamic-form/composables/use-fieldname';
import { useIsRequired } from '@/dynamic-form/composables/use-is-required';
import type { IBooleanInputData, IVisitFormInputField } from '@einfachgast/shared';
import { useExtractValidationRules } from '@/dynamic-form/composables/use-extract-validation-rules';
import { useI18n } from 'vue-i18n';
import { useVisitConditionsStore } from '@/store/visit-conditions';
import { useGroupsStore } from '@/store/groups';

const props = defineProps({
  field: {
    type: Object as PropType<IVisitFormInputField<IBooleanInputData>>,
    required: true,
    default: () => ({} as IVisitFormInputField<IBooleanInputData>),
  },
});

const { t } = useI18n();
const fieldName = useFieldname(props.field);
const isRequired = useIsRequired(props.field);
const selection = useDynamicValueGetSet(props.field);
const groupsStore = useGroupsStore();
const selectedGroup = computed(() => groupsStore.selectedGroup);

const visitConditionsStore = useVisitConditionsStore();
visitConditionsStore.registerDenyOnValidator(
  () => {
    // Ignore if another group is selected, and the field will not be visible
    const defaultGroupId = groupsStore.defaultGroup?.id as string;
    if (props.field.groups?.length > 0 && !props.field.groups.includes(selectedGroup.value?.id ?? defaultGroupId)) {
      return true;
    }

    if (typeof props.field.data.denyOn !== 'boolean') {
      return true;
    }

    return selection.value === props.field.data.denyOn ? props.field.data.denyMessage : true;
  },
);
const validationRules = useExtractValidationRules(props.field);
const options = [
  {
    title: t('pleaseSelect'),
    value: null,
  },
  {
    title: t('yes'),
    value: true,
  },
  {
    title: t('no'),
    value: false,
  },
];
const qaString = computed(() => `select-field-${fieldName.value}`);
</script>